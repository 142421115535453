.PageHeader {
  color: #fff;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 576px) {
    .container {
      font-weight: 300;
    }
  }

  @media (min-width: 992px) {
    .container {
      margin-top: -230px;
      position: relative;
      z-index: 1;
    }

    .cover-image {
      height: 744px;
      background-color: #ddd;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;

      &::before,
      &::after {
        content: "";
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 5vh;
        position: absolute;
        z-index: 1;
      }

      &::before {
        top: 0;
        box-shadow: 0px 40px 25px 0px rgba(0, 0, 0, 0.15);
      }

      &::after {
        bottom: 0;
        box-shadow: 0px -40px 25px 0px rgba(0, 0, 0, 0.15);
      }
    }

    .mobile-slider {
      display: none;
    }
  }

  @media (max-width: 992px) {
    color: #383f44;
  }

  .container {
    justify-content: space-between;
    align-items: flex-end;
  }

  .address-line-1 {
    line-height: 130%;
    font-size: 40px;
    text-shadow: 1px 1px 4px #555;
  }

  .address-line-2 {
    line-height: 1;
    font-size: 24px;
    text-shadow: 1px 1px 4px #555;
  }

  .desktop-gallery {
    width: 100%;
    max-width: 330px;
    font-size: 16px;
    text-transform: uppercase;

    @media (max-width: 992px) {
      display: none;
    }

    .images {
      position: relative;
      top: 10px;
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll auto;

      img {
        width: 60px;
        height: 40px;
        border-right: 8px solid transparent;
        box-sizing: content-box;
        cursor: pointer;
      }
    }
  }

  .mobile-slider {
    margin-bottom: 20px;

    .BrainhubCarousel__track {
      transform: translateX(-20px);
    }

    .BrainhubCarouselItem > div {
      width: 100%;
    }

    .image-wrapper {
      height: 400px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: -16px;
      border-right: 24px solid #e0edfb;
    }
  }
}
