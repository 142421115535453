.signup-modal .modal-content {
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-align: center;
}

.signup-modal {
  padding: 0 15px !important;
}

.signup-modal .modal-body {
  text-align: center;
  background-color: #dfedfb;
  padding: 48px 104px;
  position: relative;
}

.signup-modal .modal-body .close {
  position: absolute;
  top: -30px;
  right: 0;
  padding: 0;
  margin: 0;
  color: #999999;
  opacity: 1;
  text-shadow: none;
}

.signup-modal h2 {
  font-size: 64px;
  color: #d7d3cc;
  font-weight: 300;
  margin-bottom: 50px;
}

.signup-modal .modal-body h3 {
  font-size: 32px;
  color: #4f565b;
  line-height: 40px;
  margin-bottom: 16px;
}

.signup-modal .modal-body p {
  color: #4f565b;
  font-size: 16px;
  font-weight: 300;
}

.signup-modal .modal-body p a {
  color: #3a9fd1;
}

.signup-modal .modal-body form {
  margin-top: 32px;
}

.form-group {
  margin-bottom: 16px;
}

.form-control {
  font-size: 16px;
  border-radius: 4px;
}

.form-control::placeholder {
  color: #cccccc;
}

.checkbox-style,
.radio-style {
  opacity: 0;
  position: absolute;
}

.checkbox-style,
.radio-style,
.checkbox-style-3-label {
  display: inline-flex;
  vertical-align: middle;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  color: #999999;
}

.checkbox-style:checked + .checkbox-style-3-label:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  background: #ff9900;
  color: #fff;
  font-size: 9px;
}

.checkbox-style-3-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  padding: 2px;
  margin-right: 16px;
  line-height: 1;
  text-align: center;
  top: 4px;
  position: relative;
}

.checkbox-style-3-label span {
  flex: 1;
}

.signup-modal .check-bx {
  margin-bottom: 35px;
}

.signup-modal .btn {
  min-width: 240px;
  font-size: 16px;
  height: 28px;
  padding: 0;
}

.signup-modal .modal-body p.note {
  max-width: 368px;
  margin: 24px auto 0;
}

.signup-modal .modal-body p.note a {
  color: #999999;
}

.signup-modal.modal {
  background: transparent;
  backdrop-filter: blur(13px);
  --webkit-backdrop-filter: blur(13px);
  background-color: rgba(0, 0, 0, 0.6);
}

.trunc-text .more-show {
  display: none;
}

.trunc-text span.ellipse {
  display: none;
}
