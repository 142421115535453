.PurchaseControls {
  min-height: 304px;
  padding: 30px 16px 16px;

  .title {
    color: #656d72;
    font-size: 18px;
    line-height: 1;
    justify-content: space-between;
    padding-left: 7px;

    > span {
      font-size: 20px;
      padding-right: 9px;
    }
  }

  .section {
    &:first-of-type {
      margin-bottom: 56px;
    }

    &:nth-child(2) {
      margin-bottom: 52px;
    }
  }

  @media (min-width: 576px) {
    .row > div:first-of-type {
      padding-right: 24px !important;
    }

    .row > div:last-of-type {
      padding-left: 24px !important;
    }
  }

  @media (max-width: 576px) {
    .row {
      padding-bottom: 20px;

      & > div:not(:last-of-type) {
        margin-bottom: 50px;
      }
    }
  }
}
