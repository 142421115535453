.Location {
  padding: 20px 16px 21px;
  min-height: 724px;
  line-height: 1;
  color: #373a3c;

  .card-title {
    margin-bottom: 29px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 38px;

    a {
      margin-left: 16px;
      font-weight: 400;
    }
  }

  .section-title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 21px;
  }

  @media (max-width: 992px) {
    .row > div:not(:last-of-type) {
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(74, 144, 226, 0.5);
    }

    .row > div:not(:first-of-type) {
      padding-top: 30px;
    }
  }
}
