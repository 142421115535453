.Location .Neighborhood {
  .sub-title {
    margin-bottom: 36px;
  }

  .meter-list {
    list-style-type: none;
    padding: 0;

    @media (min-width: 992px) {
      max-width: 366px;
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }

    .title {
      text-align: left;
      color: #656d72;
      font-size: 18px;
      margin-bottom: 9px;
    }

    .meter-bar {
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-color: rgba(102, 204, 255, 0.2);
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;

      span {
        background: rgba(102, 204, 255, 0.5);
        width: 92px;
        height: 12px;
        border-radius: 8px;
        position: absolute;
        top: 0;
        z-index: 1;

        &:after {
          content: "";
          height: 12px;
          border-radius: 8px;
          background: rgb(102, 204, 255);
          display: block;
          width: 60px;
          margin-left: 16px;
        }
      }
    }

    .legend {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #656d72;
    }
  }

  .nh-rating {
    margin-bottom: 35px;

    ul {
      li {
        cursor: default !important;
      }

      svg {
        height: 15px !important;
        width: 15px !important;
      }
    }
  }
}
