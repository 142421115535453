.Valuation {
  padding: 17px 16px 21px;
  min-height: 294px;

  @media (max-width: 992px) {
    margin-bottom: 16px;
  }

  .card-title {
    margin-bottom: 25px;
  }

  .prop-value {
    color: #373a3c;
    margin-bottom: 6px;
    font-size: 30px;
  }

  .price-large {
    margin-bottom: 11px;
    font-size: 30px;
  }

  .rental-value {
    font-size: 30px;
    margin-bottom: 6px;
    font-weight: normal;
  }

  .value-date {
    font-size: 16px;
    margin-bottom: 17px;
    font-weight: normal;
  }

  .see-more {
    color: #373a3c;

    a {
      margin-left: 9px;
    }
  }
}
