.Schools {
  ul {
    list-style-type: none;
    padding: 0;

    &:not(:last-of-type) {
      margin-bottom: 48px;
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 33px;
      }
    }

    span {
      display: block;

      &:first-of-type {
        font-size: 20px;
        margin-bottom: 6px;
      }

      &:last-of-type {
        font-size: 16px;
      }
    }
  }

  .rank-badge {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #66ccff;
    font-size: 16px;
    margin-right: 16px;

    &.no-rank {
      color: #4f565b;
      border-color: #c0c8ce;
      font-size: 12px;
    }
  }
}
