.LeaseAndTenant {
  padding: 19px 16px 21px;
  min-height: 866px;
  position: relative;
  line-height: 1;
  color: #373a3c;
  margin-bottom: 16px;

  .card-title {
    margin-bottom: 37px;
  }

  .title {
    font-weight: 700;
    text-align: left;
    margin-bottom: 21px;
  }

  .sub-title {
    font-size: 20px;
    margin-bottom: 9px;
  }

  .value {
    font-size: 16px;
    margin-bottom: 28px;
  }

  .sep-line {
    background-color: rgba(74, 144, 226, 0.5);
    width: 100%;
    height: 1px;
    margin: 3px 0 34px;
  }

  .bottom-link {
    position: absolute;
    bottom: 0;
    z-index: 1;
    padding-bottom: 34px;
  }
}
