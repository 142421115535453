.FinancingOptions .UploadLetter {
  line-height: 1;

  .sub-title {
    padding-left: 7px;
    margin-bottom: 10px;
  }

  .content {
    line-height: 120%;
    color: #373a3c;
    font-size: 16px;
    padding: 0 14px 0 7px;
    margin-bottom: 25px;
  }

  a {
    padding-left: 7px;
  }
}
