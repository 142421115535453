.PropertyManagementOptions {
  padding: 20px 17px 41px;
  line-height: 1;
  margin-bottom: 16px;

  .card-title {
    margin-bottom: 1px;
  }

  .company-item {
    padding: 48px 8px 0 15px;

    & > div:first-child {
      margin-top: -23px;
    }

    @media (min-width: 768px) {
      .table-wrapper {
        padding-left: 25px;
      }

      &:not(:last-of-type) > div {
        border-bottom: 1px solid rgba(74, 144, 226, 0.5);
      }
    }

    @media (max-width: 768px) {
      padding: 48px 0 0 0;

      & {
        border-bottom: 1px solid rgba(74, 144, 226, 0.5);
      }
    }
  }

  .company-logo {
    background-color: rgba(204, 204, 204, 0.45);
    background-repeat: no-repeat;
    background-size: contain;
    width: 135px;
    height: 80px;
    margin-bottom: 14px;
  }

  .company-details {
    color: #373a3c;
    line-height: 1.5;
    font-size: 16px;
    padding: 0;
    list-style-type: none;
    word-break: break-word;
  }

  .company-name {
    font-size: 20px;
    color: #373a3c;
    margin-top: 25px;
    margin-bottom: 18px;
  }

  table {
    font-size: 18px;
    color: #656d72;
    margin-bottom: 40px;

    tbody tr {
      border-bottom: 1px solid rgba(74, 144, 226, 0.5);
    }

    td {
      padding: 9px 8px 8px 8px;
    }
  }
}
