.FinancingOptions .LoanTerms {
  line-height: 1;

  .dropdown {
    max-width: 280px;
  }

  table {
    color: #656d72;

    th {
      font-size: 18px;
      font-weight: 400;
      text-align: right;
    }

    td {
      font-size: 20px;
      padding-top: 10px;
    }

    thead,
    tbody tr {
      border-bottom: 1px solid rgba(74, 144, 226, 0.5);
    }

    a {
      font-size: 18px;
    }

    .company-logo {
      background-color: rgba(204, 204, 204, 0.45);
      background-repeat: no-repeat;
      background-size: contain;
      width: 98px;
      height: 40px;
    }

    .text-small {
      font-size: 12px;
      line-height: 120%;
      text-align: right;
      padding: 8px 0 1px;
    }

    button {
      background-color: #66ccff;
      border: 0;
      width: 139px;
      height: 28px;
      font-size: 14px;
      padding: 0;
      margin-top: -3px;
    }

    .td-logo {
      padding-left: 18px;
      vertical-align: middle;
    }
  }

  .bottom-statement {
    color: #373a3c;
    line-height: 120%;
    max-width: 419px;
    padding-top: 32px;
  }
}
