.contact-us-page .mainsection:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100 */
  background: rgba(0, 0, 0, 0.14);
  background: -moz-linear-gradient(top, #000000 0%, rgba(0, 0, 0, 0.1) 30%);
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 30%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1411764705882353) 0%,
    rgba(0, 0, 0, 0.1) 30%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
  width: 100%;
  height: 100%;
}

.contact-us-page .mainsection {
  min-height: 90vh;
  display: flex;
  align-items: flex-end;
  padding: 60px 0 40px;
  justify-content: flex-end;
  position: relative;
  background-position: center !important;
}

.contact-us-page .main_content {
  padding-top: 0;
}

.contact-us-page .main_content h1 {
  color: #d7d3cc;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 30px;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  position: relative;
  z-index: 1;
}

.contact-info {
  background-color: #f9e6c8;
  padding: 27px 0;
}

.contact-info-inner {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.contact-info .text-box {
  text-align: center;
}

.contact-info .text-box h3 {
  color: #383f44;
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.contact-info .text-box p {
  color: #383f44;
  font-size: 18px;
  font-weight: 400;
  margin: 7px 0 20px;
  padding: 0;
}

.contact-info .text-box span {
  display: block;
  color: #383f44;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.contact-info .text-box a {
  display: block;
  color: #383f44;
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  transition: 0.3s ease all;
}

.contact-info .text-box a:hover {
  color: #f29c1a;
  transition: 0.3s ease all;
}

#map {
  height: 760px;
}
