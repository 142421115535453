.SellingCard {
  border-radius: 5px;
  background-color: #fff;
  padding: 19px 16px 17px;
  width: 100%;
  color: #373a3c;

  @media (max-width: 992px) {
    margin-bottom: 16px;
  }

  .header {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
    line-height: 110%;

    span {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
    }

    a {
      font-size: 20px;
    }
  }

  .type {
    font-size: 16px;
  }

  .price-and-links {
    margin: 6px 0 8px;
    justify-content: space-between;

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: -22px;

      a {
        font-size: 16px;
      }
    }
  }

  .list-price {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .price-input-wrapper {
    position: relative;

    &:before {
      content: "$";
      color: #373a3c;
      font-size: 40px;
      line-height: 1;
      font-weight: 200;
      padding: 7px 0 0 10px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  input {
    font-size: 40px;
    font-weight: 200;
    width: 100%;
    max-width: 271px;
    height: 56px;
    border: 1px solid #c0c8ce;
    border-radius: 4px;
    padding-left: 30px;
  }

  .btn-review {
    text-transform: uppercase;
    box-shadow: none;
  }

  .btn-buy-now {
    color: #3a9fd1;
    background-color: transparent;
  }

  .controls-desktop {
    padding-top: 16px;
    display: flex;
    align-items: center;

    button {
      font-size: 16px;
      width: 151px;
      border-radius: 3px;
    }

    .btn-review {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .price-and-controls-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .price-desktop,
    .controls-desktop {
      display: none;
    }

    .price-and-links .links {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 170px;
    }

    .price-and-controls-mobile {
      display: flex;

      .controls {
        display: flex;
        flex-direction: column;

        .btn-review {
          width: 100%;
          height: 56px;
          margin-bottom: 12px;
        }
      }
    }

    .price-input-wrapper::before {
      padding-left: 24px;
    }
  }

  @media (max-width: 576px) {
    input {
      max-width: 100%;
      margin-bottom: 20px;
    }

    .price-and-links .links {
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      line-height: 1;

      a {
        margin-bottom: 10px;
      }
    }
  }
}
