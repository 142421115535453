.GrossYield {
  border-radius: 6px;
  padding: 27px 24px 21px;
  margin-bottom: 16px;

  .section {
    line-height: 1;

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }

    > div {
      color: #656d72;
      text-transform: uppercase;
      font-size: 17px;
      margin-bottom: 10px;
      display: flex;
    }

    span {
      color: #373a3c;
      font-size: 48px;
      font-weight: 200;
      position: relative;

      @media (max-width: 400px) {
        font-size: 38px;
      }
    }

    .lower-text {
      font-size: 17px;
      width: 80px;
      position: absolute;
      bottom: 10px;
      text-indent: 1px;
      z-index: 1;
    }
  }

  button {
    display: block;
    margin: 15px 0 0 auto;

    @media (min-width: 992px) {
      margin: -3px -1px 0 auto;
    }

    @media (max-width: 768px) {
      margin: 30px 0 0 auto;
    }
  }
}
