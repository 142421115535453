.house-page {
  padding-bottom: 108px;

  @media (min-width: 992px) {
    position: relative;
    z-index: 1;
  }

  .main-row {
    margin-bottom: 44px;
  }

  & > .nav-tabs {
    .nav-item {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-bottom: 3px;

      &.active {
        font-weight: 700;
        border-bottom: 0;
      }
    }
  }
}

.page-nav {
  padding-bottom: 6px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgb(102, 204, 255);

  a {
    color: rgb(58, 159, 209);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.active {
      color: #000;
    }

    &:not(:last-child) {
      margin-right: 26px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: pre;

    a {
      line-height: 1;
    }
  }
}
