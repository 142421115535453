.InvestParams {
  .title {
    margin-bottom: 6px;
  }

  .list {
    padding: 12px 0 0;
    margin-bottom: 0;

    li {
      justify-content: space-between;
      font-size: 16px;
      line-height: 1;
      color: #656d72;
      padding: 10px 9px 7px;
      border-bottom: 1px solid rgba(74, 144, 226, 0.5);

      span:last-of-type {
        font-size: 18px;
      }
    }
  }
}
